main.landing {
  section.top {
    position: relative;
    @extend %flex-centered, %column;
    .background {
      width: 100%;
      top: 0;
      left: 0;
      height: 80%;
      position: absolute;
      background: var(--primary-color);
      overflow: hidden;

      &::before {
        background: url("../images/priapusBackground.png") center fixed;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: cover;
        opacity: 0.2;
        filter: saturate(0.6);
        .background-layer {
          background-color: #19caff;
          position:absolute;
          z-index: 1;
          top:0;
          left:0;
          width: 100%;
          height: 100%;
        }
      }

      img {
        height: 50vh;
        width: 30vw;
        object-fit: cover;
        position: absolute;
        object-position: center top;
        z-index: 2;
        bottom: 0;
        filter: brightness(0.7);
        right: 0;
      }
    }
    .text-area,
    .image-area {
      position: relative;
      z-index: 2;
    }

    div.text-area {
      width: 50%;
      text-align: center;
      padding-top: 20vh;
      & > div {
        color: var(--light-color);
      }
    }
    .image-area {
      img {
        height: 70vh;
        width: auto;
        object-fit: cover;
        object-position: center;
        border-radius: $sm;
        box-shadow: 0 4px 16px 3px rgba(0, 0, 0, 0.16);
      }
    }
  }
  .bg-map {
    background-size: cover;
    background: url("../images/bg.png") fixed center;
  }
  .partners {
    img {
      height: 80px;
      width: auto;
    }
  }
  .features {
    margin:  0;
    .text-area,
    .features-area {
      width: 50%;
      .feature-col {
        .feature {
          cursor: pointer;
          transition: 0.3s;
          .wwd {
            font-size: 1rem;
          }
          &:hover {
            background: var(--primary-color);
            color: white;
            .wwd {
              color: white;
            }
          }
        }
      }
    }
    .text-area {
      padding-right: 5%;
      box-sizing: border-box;
    }
  }
  .rambles {
    & > p {
      width: 50%;
    }
    .ramble-area {
    }
  }

  .self-ad {
    .text-area,
    .image-area {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }

  .pricing {
    margin-bottom: 10vh;
    .pricing-area {
      .pricing-box {
        width: 100%;
        margin: 0 2%
      }
    }
  }
}

//components
.ramble {
  width: 48%;
  img {
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .pricing-area {
      flex-direction: column;
     .pricing-box {
        margin: 4% 2% !important
      }
    }
}
