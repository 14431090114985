@use "../../../../style/scss/vars.scss" as vars;

.pricing-page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div {
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
    font-family: inherit; // Inherit font family from the parent element
  }

  --card-border: #e1e4ed;
  --background: #f5f5f9;

  font-family: vars.$ff-poppins;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 151px;
  background: var(--background);
  min-height: 100vh;

  & > h1 {
    font-weight: vars.$fw-bolder;
    font-size: vars.$fs-2xl;
    margin-bottom: 24px;
    line-height: 40px;
    color: vars.$secondary-0;
  }

  .pricing-toggle {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: vars.$fw-semibold;
    margin-bottom: 24px;
  }

  .pricing-container {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    gap: 22px;
    flex-wrap: wrap;
    justify-content: center;

    .pricing-card {
      border: 1px solid var(--card-border);
      padding: 24px 24px 24px;
      width: 304px;
      border-radius: vars.$radius-lg;
      background: #fff;
      display: flex;
      flex-direction: column;

      .pricing-card__header {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e4ed;
        margin-bottom: 20px;
        gap: 12px;

        & > h4 {
          line-height: 24px;
          font-size: vars.$fs-lg;
          font-weight: vars.$fw-semibold;
          color: vars.$secondary-0;
        }

        & > p {
          line-height: 21px;
          color: vars.$secondary-0;
        }

        .price {
          display: flex;
          align-items: center;
          gap: 8px;

          h2 {
            font-size: vars.$fs-2xl;
            font-weight: vars.$fw-semibold;
            line-height: 40px;
            color: vars.$secondary-0;
          }
          span {
            font-size: vars.$fs-xs;
            color: #b4b9c9;
            font-weight: vars.$fw-semibold;
          }
        }
      }

      .pricing-card__main {
        flex: 1;
        ul {
          display: flex;
          flex-direction: column;
          gap: 14px;
          margin-bottom: 0;
        }

        li {
          width: 100%;
          display: flex;
          line-height: 14px;
          align-items: center;
          justify-content: space-between;
          font-weight: vars.$fw-semibold;
          color: vars.$secondary-0;

          svg {
            height: 16px;
            width: 16px;
          }

          svg.checkIcon {
            color: vars.$primary-0;
          }

          svg.crossIcon {
            color: vars.$error-0;
          }
        }
      }

      .button {
        a {
          width: 100%;
        }

        button {
          font-weight: vars.$fw-bold;
          span {
            font-size: vars.$fs-xs;
          }
        }
      }

      &.highlight {
        background: vars.$primary-0;

        &,
        svg.checkIcon,
        h1,
        h4,
        h2 {
          color: white !important;
        }

        .pricing-card__header {
          & > p {
            color: #fff;
          }
          .price {
            span {
              color: #e1e4ed;
            }
          }
        }

        .pricing-card__main {
          ul {
            li {
              color: #fff;
            }
          }
        }

        .button {
          button {
            background: #fff;
            color: vars.$primary-0;
          }
        }
      }
    }
  }
}
