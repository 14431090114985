@use "../../../style/scss/vars.scss" as vars;

.button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.primary {
    background: vars.$primary-0;
    color: #fff;
    font-weight: vars.$fw-medium;

    &:hover {
      background: #726de8;
    }

    &.disabled {
      background: vars.$primary-3;
      pointer-events: none;
    }
  }

  &.secondary {
    background: vars.$secondary-0;
    color: #fff;
    font-weight: vars.$fw-medium;
  }

  &.ghost {
    background: white;
  }
  &.outline {
    border: 1px solid vars.$primary-0;
    font-weight: vars.$fw-medium;
    color: vars.$primary-0;
  }

  &.disabled-primary {
    background: vars.$primary-3;
    color: #fff;
    font-weight: vars.$fw-semibold;
  }

  &.rounded-sm {
    border-radius: vars.$radius-sm;
  }

  &.rounded-md {
    border-radius: vars.$radius-md;
  }
  &.rounded-lg {
    border-radius: vars.$radius-lg;
  }

  &.fw-medium {
    font-weight: vars.$fw-medium;
  }

  &.fw-semibold {
    font-weight: vars.$fw-semibold;
  }

  &.fw-bold {
    font-weight: vars.$fw-bold;
  }
}
