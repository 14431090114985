.newsquawk-audio-container {
  // border: 1px solid #d5d5d5;
  border-radius: 4px;
  margin-top: 2px;
  padding: 0;
  container-type: inline-size;
  container-name: audioContainer;

  .audio_player {
    width: 100%;
    border-radius: 4px;
    transition: all 200ms ease;
    background: white;
    padding: 0;
    display: grid;
    grid-template-columns: 31px 0.9fr 1fr;
    grid-row-gap: 2px;

    &:hover {
      background: none;
    }

    .audio_status,
    h3 {
      border: 1px solid #d5d5d5;
      margin-right: 0;
      padding-left: 14px;
      height: 33px;
      display: inline-flex !important;
      align-items: center;
    }

    .audio_status {
      border-right: 0;
      border-radius: 4px 0 0 4px;
      order: 1;

      .play_button {
        span {
          // background-image: url("/src/style/images/audio-newsquawk-play.png");
          margin-left: 0;
          background-image: url("../images/newsquawk-audio-play-icon.svg");
          height: 16px;
          width: 16px;
        }
      }

      .stop_button {
        span {
          // background-image: url("/src/style/images/audio-newsquawk-play.png");
          margin-left: 0;
          background-image: url("../images/newsquwak_audio_stop.svg");
          height: 16px;
          width: 16px;
        }
      }
    }

    h3 {
      border-left: 0;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 600;
      color: #202224;
      width: 100%;
      border-radius: 0;
      padding-left: 8px;
      border-right: 0;
      order: 2;
    }

    .volume_controls {
      // display: none;
      order: 3;
      border: 1px solid #d5d5d5;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      display: flex;
      align-items: center;
      padding-right: 10px;

      .volume_slider {
        width: 62px;
        background: #f5f6fa;
        border: 1px solid #d5d5d5;
        height: 4px;
        margin-left: auto;
        opacity: 1;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #d4d4d4; /* Border color */
        border-radius: 50%; /* Make it round */
        height: 10px;
        width: 10px;
        background-color: #1d1d4e; /* Background color */
        cursor: pointer;
      }

      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #d4d4d4; /* Border color */
        border-radius: 50%; /* Make it round */
        height: 10px;
        width: 10px;
        background-color: #1d1d4e; /* Background color */
        cursor: pointer;
      }
    }

    ul {
      position: relative;

      top: 0 !important;
      margin: 0;
      transition: none;
      background: none;
      display: flex;
      flex-direction: column;
      gap: 6px;

      grid-column: 1/-1;
      order: 4;

      border: 0;
      height: 0;
      padding: 0;

      display: none;

      // transition: all 200ms ease;

      li {
        border-radius: 4px;
        overflow: hidden;

        a {
          height: 29px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background: #f6f7f9;
          color: #202224;
          font-weight: 500;

          .icons {
            display: none;
          }
        }

        &:hover {
          border-radius: 4px;

          a {
            color: #202224;
            background: rgba(52, 52, 89, 0.2);
          }
        }
      }

      &:after {
        display: none;
      }
    }

    &.activated {
      background: unset;

      ul {
        height: auto;
        padding: 6px;
        border: 1px solid #d5d5d5;
        display: flex;
        border-radius: 4px;

        li {
          a {
            height: 29px;
            width: 176px;
          }
        }
      }
    }
  }

  @container audioContainer (min-width:200px) {
    .audio_player {
      grid-template-columns: 31px 0.7fr 2fr;

      .volume_controls {
        .volume_slider {
          width: 243px;
        }
      }
      &.activated {
        ul {
          padding: 6px 10px;
          li {
            a {
              height: 38px;
              width: 360px;
            }
          }
        }
      }
    }
  }
}

// 9ca1a8

.newsquawk-audio-container.dark {
  .audio_player {
    background: #384251;

    h3 {
      color: white;
    }

    .audio_status {
      .play_button {
        span {
          filter: invert(1);
        }
      }
    }

    .audio_status,
    h3 {
      border-color: #9ca1a8;
    }

    .volume_controls {
      border-color: #9ca1a8;

      .volume_slider {
        background: #4d5562;
        border: 1px solid #d5d5d5;
      }

      input[type="range"]::-webkit-slider-thumb {
        border-color: #d4d4d4; /* Border color */
        background-color: #66dbc4; /* Background color */
      }

      input[type="range"]::-moz-range-thumb {
        border-color: #d4d4d4; /* Border color */
        background-color: #1d1d4e; /* Background color */
      }
    }
    ul {
      border-color: #9ca1a8;

      li {
        a {
          background: #303742;
          color: white;
        }

        &:hover {
          border-radius: 4px;

          a {
            background: #515a67;
          }
        }
      }
    }

    // @container audioContainer (min-width:200px) {
    //   .audio_player {
    //     grid-template-columns: 31px 0.7fr 2fr;

    //     .volume_slider{
    //       width: 243px;
    //       background: Red;
    //     }
    //   }
    // }
  }
}
