.i-modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
  cursor: pointer;
}
.lightbox-parent {
  // max-width: 1024px;
  position: relative;
  .tweet-row {
    display: flex;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    .rumble-column-img {
      max-width: 40px;
      border-radius: 50%;
    }
    .rumble-content {
      color: #eee;
    }
  }
  .media-preview {
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 10px 0;
  }
  .preview-item {
    width: 100px;
    height: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
  }
  .text-username {
    color: #ccc;
  }
  .media-item {
    position: relative;
    margin-top: 10px;
    img {
      max-width: 100%;
    }
    .prev {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      width: 36px;
      img {
        position: inherit;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -16px;
      }
    }
    .next {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      width: 36px;
      img {
        position: inherit;
        width: 100%;
        top: 50%;
        left: 0;
      }
    }
  }
}
.imageModal-background {
  background-color: #222f3e;
}
