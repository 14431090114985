@use "../../../style/scss/vars.scss" as vars;

.text-input-container {
  position: relative;
  input {
    border: none;
    width: 100%;
    border: 1px solid vars.$input-border;
    box-shadow: 0px 1px 4px vars.$shadow-1;
    padding: 11px 16px;
    font-size: 16px;
    outline: none;

    &:focus {
      border: 1px solid vars.$primary-0;
    }

    &.radius-lg {
      border-radius: vars.$radius-lg;
    }
    &.radius-sm {
      border-radius: vars.$radius-sm;
    }
    &.radius-md {
      border-radius: vars.$radius-md;
    }

    &:disabled {
      background: vars.$input-border;

      &::placeholder {
        color: vars.$placeholder;
      }
    }
  }

  svg {
    color: vars.$primary-0;
  }

  &.error {
    margin: unset;
    input {
      border-color: vars.$error-0;
    }
    svg {
      color: vars.$error-0;
    }
  }

  .icon,
  .custom-icon {
    position: absolute;
    height: 100%;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

span.error {
  display: block;
  line-height: 21px;

  .custom-icon {
    width: fit-content;
    height: fit-content;

    &.right {
      right: 0;
    }
  }
  .text-input-container .icon.loading {
    color: black !important; // Ensure this takes precedence over .error svg
  }
}
