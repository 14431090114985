.stat-card {
    background-color: #effbffd9 !important;
  
    .stat-body {
      display: flex;
      justify-content: space-between;
      font-size: 40px;
    }
    .stat-icon {
      color: #19caff;
    }
  }
  
  .traders-table {
    width: 100%;
  }