@use "../../../../style/scss/vars.scss" as vars;

.register-page-wrapper {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
    font-family: inherit; // Inherit font family from the parent element
    color: inherit;
  }
  color: vars.$secondary-0;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  align-items: center;
  // min-height: 100%;
  flex: 1;
  gap: 74px;
  padding: 0 5% 5%;

  .payment-success {
    margin-top: 111px;
  }

  .register-page-wrapper__header {
    display: flex;
    align-items: center;

    .back-btn {
      svg {
        height: 15px;
        width: 8px;
        display: block;
      }
    }
  }

  .register-page-container {
    // padding-block: 20%;
    width: 100%;
    height: 100%;
    flex: 1;
    max-width: 592px;
    margin-inline: auto;
    color: vars.$secondary-0;
    display: flex;
    flex-direction: column;

    .register-page-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 50.4px;
      text-align: left;
      margin-bottom: 28px;
    }
    .line {
      border-top: 1px solid vars.$input-border;
      flex: 1;
      position: relative;
      top: 2px;
      max-width: 539px;
    }

    .or {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      text-align: left;
      margin-block: 32px;
      color: #1d1d4b99;
    }

    form {
      border: 1px solid vars.$input-border;
      border-radius: vars.$radius-lg;
      display: flex;
      flex-direction: column;
      gap: 14px;
      // margin-top: 24px;
      padding: 10px;
      box-shadow: 0px 1px 4px 0px #19213d14;

      .personal-info-container {
        display: flex;
        gap: 16px;

        > * {
          flex: 1;
        }
      }

      // button {
      //   margin-top: 14px;
      // }
    }

    .register-page-subtitle {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: vars.$secondary-0;
    }

    .sign-up-with-google-btn {
      width: fit-content;
      border: 1px solid #e1e4ed;
      background-color: white;
      color: #0000008a;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      display: flex;
      gap: 14px;
      padding-inline: 24px;
      padding-block: 16px;
      border-radius: 10px;
      width: 241px;
      height: 56px;
      white-space: nowrap;
    }
  }

  .bottom-action {
    // width: 80% !important;
  }

  .toc-container ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
