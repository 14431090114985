.incremental-input {
    display: flex;
    .increase {
      background-color: #e7f6fc;
      width: 45px;
      text-align: center;
      padding: 10px;
      cursor: pointer;
    }
  
    .decrease {
      background-color: #c4c4c4;
      width: 45px;
      text-align: center;
      padding: 10px;
      cursor: pointer;
    }
    input {
      width: 80px;
      text-align: center;
      // flex: 3;
    }
  }