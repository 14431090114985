@import "../scss/style";

.login-title {
  font-weight: 700;
}

.input-field {
  border: 1px solid #5e5e5e !important;
  background: transparent !important;
  border-radius: 5px !important;
  color: #5e5e5e !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.input-field::placeholder {
  color: #5e5e5e;
}
.dark-input-field::placeholder {
  color: #9ca1a8;
}

.dark-input-field {
  border: 1px solid #9ca1a8 !important;
  background: transparent !important;
  border-radius: 5px !important;
  color: #9ca1a8 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.register-note {
  font-size: 12px;
  margin-top: 10px;
}

.register-title {
  margin-bottom: 7px;
  font-weight: 600;
}

.terms-checkbox-div {
  text-align: right;
  font-size: 12px;
  margin-top: 5px;

  .terms-checkbox-text {
    margin-right: 5px;
  }
}

.login-link {
  text-decoration: underline;
  color: #1d1d4b;
}

.login-link:hover {
  color: #1890ff;
}

.signin-btn-container {
  width: 70%;
  margin-left: auto;
  margin-top: 10px;
}

.forgot-password-note {
  margin-top: 5px;
  font-size: 12px;
}

// Terms & Privacy Style
.terms-link-text {
  font-size: 12px;
  text-decoration: underline;
}

.article-title-back-text {
  text-decoration: none;
}

.article-title-back-text:hover {
  color: #1890ff;
  cursor: pointer;
}

.terms-link-text:hover {
  color: #1890ff;
  cursor: pointer;
}

.terms-iframe-div {
  .terms-iframe {
    width: 100%;
    height: 500px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
