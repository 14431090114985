.v2-drawer {
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .app-selector-list-container {
    height: 453px;
    flex: 1;

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0px solid #d5d5d5 !important;
      margin: 0 0 -7px 21px !important;
      width: 86%;
    }

    .ant-tabs-card {
      border: 1px solid rgba(52, 52, 89, 0.1);
      // border-bottom: none;
      height: 454px;
    }
    .ant-tabs-top > .ant-tabs-nav {
      // margin: 0 0 -7px 21px !important;
      // width: 92%;
    }

    .ant-radio-group,
    .ant-radio-group-solid {
      height: auto !important;
      // padding: 20px 25px 40px;
      // display: Grid;
      // grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
      gap: 10px;

      display: flex;
      flex-wrap: wrap;

      &.tv-top-section {
        display: flex;
        flex-wrap: wrap;
        gap: 21px;
      }

      .ant-radio-button-wrapper-checked,
      .ant-radio-button-wrapper {
        &:not([class*=" ant-radio-button-wrapper-disabled"]) {
          .app-select-label {
            background: transparent !important;
            color: #17181c;
          }
        }
      }

      .ant-radio-button-wrapper-checked {
        &:not([class*=" ant-radio-button-wrapper-disabled"]) {
          background: #e2e4e9;
          color: black;

          &:hover {
            background: #f1f1f4 !important;
          }
        }
      }
    }

    .ant-tabs {
      font-size: 13px;
    }
    .ant-tabs-tab {
      border-top: none !important;

      &:first-child {
        border-left: none !important;
      }
    }

    .ant-tabs-nav {
      // margin: 0 0 -5px 24px !important;
      margin: 0;
      position: absolute;
    }

    .ant-tabs-tab-btn {
      color: #1d1d4b;
    }

    // .ant-tabs-tab {
    //   background-color: white !important;
    //   border: none !important;
    //   padding: 6px 0px !important;
    //   margin-right: 10px !important;
    // }

    .ant-tabs-tab:hover {
      color: #1d1d4b !important;
    }

    .ant-tabs-tab-active {
      color: #fff !important;
      border-bottom: none;

      .ant-tabs-tab-btn {
        color: #000;
        // text-align: center !important;
        font-size: 14px !important;
        font-style: normal !important;
        line-height: 16px !important;
        /* 100% */
      }
    }
  }

  .ant-drawer-body {
    padding-bottom: 20px !important;
    margin: 0px !important;
  }

  .ant-drawer-mask {
    background: transparent !important;
  }

  .ant-drawer-header {
    padding: 34px 0 5px 0 !important;
    border-bottom: 0px !important;
  }

  //Scroll Bar Style Start
  a {
    color: #00deab !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.5) !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    // background: #555 !important;
    background: #d9d9d9 !important;
  }

  //Scroll Bar Style End
  .ads-div {
    text-align: -webkit-right;
  }

  // .ant-drawer-wrapper-body {
  //   padding: 0px 50px 0px 50px !important;
  // }
  .ant-drawer-wrapper-body {
    padding: 0px 15px 0px 8px !important;
  }

  // Article List Drawer CSS Start

  .ant-drawer-content {
    background-color: white !important;
  }

  .article-list-container {
    -webkit-font-smoothing: auto !important;
    margin-bottom: 100px;

    .results {
      color: #3c3c3c;
      text-align: right;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px; /* 76.923% */
    }

    .article-list-item {
      background-color: white;
      margin-top: 5px;
      padding: 10px;
      padding: 20px 30px 20px 30px;
      margin-right: 20px;
      border-radius: 4px;

      .time-div {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        /* 116.667% */
      }

      .article-list-title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .breaking-tag {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
        background-color: #ff644e;
        cursor: pointer;
        // width: 70px;
        border-radius: 0px;
        padding: 1px 4px 2px 4px;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0px solid #d5d5d5 !important;
      margin: 0 0 15px 0px !important;
    }

    .ant-tabs-tab {
      background-color: white !important;
      border-radius: 15px !important;
      border: 1px solid #d5d5d5 !important;
      color: #1d1d4b !important;
      height: 30px !important;
      margin-right: 10px !important;
    }

    .ant-tabs-tab:hover {
      background-color: #d5d5d5 !important;
      color: black;
    }

    // .ant-tabs-tab-active {
    //   background-color: #303742 !important;

    //   .ant-tabs-tab-btn {
    //     color: #fff !important;
    //     text-align: center !important;
    //     font-size: 14px !important;
    //     font-style: normal !important;
    //     font-weight: 400 !important;
    //     line-height: 16px !important;
    //     /* 100% */
    //   }
    // }

    .list-pagination {
      margin: 20px 20px 0 0;

      button {
        color: black !important;
      }

      li {
        a {
          color: black !important;
        }
      }

      .ant-pagination-item {
        opacity: 0.5;
      }

      .ant-pagination-item-active {
        a {
          background-color: #1d1d4b !important;
          color: white !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      background: black !important;
      height: 4px !important;
    }

    .article-list-heading {
      text-align: right;
    }

    .bookmark-icon {
      width: 12px;
      height: auto;
    }

    .refresh-icon {
      width: 14px;
      margin-right: 20px;
      height: auto;
    }

    .bookmark-icon.link {
      margin-right: 10px;
    }

    .share-icon {
      width: 14px;
      height: auto;
      cursor: pointer;
    }
  }

  // Article List Drawer CSS End

  // Article Detail/Reuters Detail Drawer CSS End
  .article-detail-container {
    -webkit-font-smoothing: auto !important;
    background-color: white !important;
    padding: 25px 50px 25px 50px;
    margin-right: 20px;
    img {
      width: -webkit-fill-available !important;
      height: auto !important;
    }

    .breadcrumb {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
      /* 100% */
      letter-spacing: 0.7px;
    }

    .article-detail-title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1.2px;
      margin-top: 10px;
    }

    .article-detail-text {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      img {
        padding-right: 0px !important;
      }
    }

    .article-detail-date {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.7px;
      margin-top: 20px;
      text-align-last: left;
    }

    .share-icon {
      width: 14px;
      height: auto;
      cursor: pointer;
    }

    .related-articles {
      margin-top: 15px;

      .article-detail-card {
        position: relative;
        text-align: center;
        cursor: pointer;

        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
          max-height: 140px;
          object-fit: contain;
          opacity: 0.4;
          background: rgba(0, 0, 0, 0.5);
        }

        .article-detail-image-center-text {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80%;
          font-weight: 900;
          color: white;
          transform: translate(-50%, -50%);
        }
      }

      .article-detail-image-container {
        margin: 5px;
        display: block;
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }

  // Article Detail/Reuters Detail Drawer CSS End

  // Unlock New Feature Drawer CSS Start
  .unlock-feature-table {
    width: 100%;
    font-size: 10px;

    tr:first-child {
      background: #000;
      color: #ffffff;
      color: white;
    }

    tr:last-child td {
      background: #ffffff !important;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      width: 40%;
      font-weight: 500;
    }

    td {
      background: rgba(29, 29, 78, 0.1);
      text-align: center;
    }

    td,
    th {
      border: 1px solid #ffffff;
      padding: 3px 6px;
      width: 21%;
      color: white;
    }
  }

  .feature-list-table {
    width: 100%;
    font-size: 11px;

    tr:first-child {
      background: #000;

      th {
        color: white !important;
      }
    }

    th:first-child,
    td:first-child {
      text-align: left;
      width: 40%;

      color: #5e5e5e;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }

    td {
      background: rgba(29, 29, 78, 0.1);
      text-align: center;
    }

    td,
    th {
      border: 1px solid #ffffff;
      padding: 3px 7px;
      width: 21%;
      color: #8989a1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      line-height: 12px;
    }
  }

  .add-feature-btn {
    margin: 5px 0;
    width: 40%;
    margin-left: auto;
  }

  .have_promo_code {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 4px;
    }
    .promo_full_text {
      color: #949494;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px; /* 83.333% */

      .promo_text {
        color: #606060;
        font-family: "Helvetica Neue", sans-serif !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
      }
    }
  }

  .add-feature-note {
    margin: 0px 0;
    text-align: left;
    color: #5e5e5e;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
  }

  .singin-btn {
    width: 179px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    margin: 0 -5px;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
      height: 16.056px;
    }

    .singin-text {
      color: #1f1f1f;
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      margin-left: 5px;
    }
  }

  .singin-btn:hover {
    background: #d0cfcf;
  }

  // Unlock New Feature Drawer CSS End

  // General Settings Drawer CSS Start
  .sidebar-color-choose-tile {
    width: 15px;
    height: 12px;
    border: 1px solid #5e5e5e;
    border-radius: 2px;
  }

  .sidebar-color-choose,
  .dark-mode-switch {
    margin: 5px 10px;
  }

  .ant-switch {
    background-color: #9ca1a8 !important;
  }

  .ant-switch-checked {
    background-color: #8989a1 !important;
  }

  .ant-switch:focus {
    box-shadow: none !important;
  }

  .ant-switch-checked:focus {
    box-shadow: none !important;
  }

  // General Settings Drawer CSS End

  // Account Settings Drawer CSS Start
  .v2-account-settings-drawer {
    position: relative;
    margin-right: 10px;

    .ant-picker {
      padding: 0 11px 0 0 !important;
    }

    .ant-picker-input > input {
      padding: 4px 11px !important;
    }

    .profile-update-form {
      margin-left: 10px;
    }

    .personal-form-topic {
      color: #454e5c !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      margin: 5px -10px;
      padding-left: 10px !important;
    }
    .please-verify-text {
      position: absolute;
      width: 140px;
      margin-left: 8px;
      display: inline;
      margin-top: 5px;
      color: #1d1d4b;
      font-family: Helvetica !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }

    .unverified-text-box {
      width: 200px;
      height: 27px;
      flex-shrink: 0;
      border-radius: 5px;
      border: 1px solid #5e5e5e;
      margin-right: 10px;
    }

    .resend-code-unverified {
      color: #1d1d4b;
      text-align: left;
      font-family: Helvetica;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      text-decoration-line: underline;
    }

    .email-message {
      margin-top: -5px;
      margin-left: 7px;
      color: #5e5e5e;
      font-family: Helvetica;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 9px;
    }

    .dark-personal-form-topic {
      color: #9ca1a8 !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      margin: 5px 0 !important;
      padding-left: 10px !important;
    }

    .input-field-email {
      border-radius: 5px !important;
      background: rgba(233, 233, 237, 0.5) !important;
      color: #5e5e5e !important;
      border: none !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 12px !important;
      padding: 9px !important;
    }

    .dark-input-field-email {
      border-radius: 5px !important;
      background: #4c5563 !important;
      color: #9ca1a8 !important;
      border: none !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 12px !important;
      padding: 9px !important;
    }

    .change-email,
    .change-password {
      margin: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px !important;
    }

    .profile-heading {
      font-weight: 500;
      margin-bottom: 5px;
      text-align-last: start;
      cursor: pointer;
      color: #000;
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      line-height: 14px;
    }

    .profile-heading-email {
      font-weight: 500;
      margin-bottom: 5px;
      text-align-last: end;
    }

    .profile-sub-title {
      font-weight: 700;
      padding-left: 10px;
    }

    .profile-sub-text {
      padding-left: 30px;
    }

    .user-update-btn {
      margin: 0 0 10px 0;
      width: 60%;
      margin-left: auto;
    }

    .profile-btn-div {
      margin: 5px 0;
      width: 55%;
      margin-left: auto;

      .disabled:hover {
        background-color: #ffffff !important;
      }
    }

    .profile-last-div {
      text-align: right;
      font-size: 10px;
      font-weight: 600;
    }

    .confirm-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: white;
      padding: 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;

      .confirm-dialog-text {
        font-weight: 500;
        color: #ff644e;
      }

      .delete-dialog-btn {
        background: #ff644e80;
        padding: 6px 15px 4px 15px;
        font-size: 10px;
        line-height: 10px;
        border: 1px solid #5e5e5e;
        border-radius: 5px;
        margin: 5px;
        width: 65px;
        height: 22px;
        font-style: normal;
        font-weight: 900;
        color: #5e5e5e;
      }
    }

    .confirm-dialog-premium-apps {
      position: absolute;
      width: 180px;
      height: 72px;
      top: 69%;
      left: 70%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: white;
      padding: 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;

      .confirm-dialog-text-premium-apps {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 9px;
        /* 100% */
        color: #ff644e;
        margin-bottom: 5px;
      }
    }

    .enter-password-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;

      .enter-password-dialog-text {
        font-weight: 500;
      }

      .submit-btn-div {
        text-align: center;

        .submit-dialog-btn {
          border: 1px solid #5e5e5e;
          border-radius: 5px;
          margin: 5px;
          font-style: normal;
          font-weight: 900;
          background: rgba(213, 213, 213, 0.5);
          color: #5e5e5e;
          padding: 6px 15px 4px 15px;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }

    .submit-dialog-btn {
      border: 1px solid #5e5e5e;
      border-radius: 5px;
      margin: 5px;
      // font-family: Helvetica Bold;
      font-style: normal;
      font-weight: 900;
      background: rgba(213, 213, 213, 0.5);
      color: #5e5e5e;
      padding: 6px 15px 4px 15px;
      font-size: 10px;
      line-height: 10px;
    }

    .continue-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;
      text-align: center;

      .continue-dialog-text {
        font-weight: 500;
        margin-bottom: 5px;
      }

      .submit-dialog-btn {
        border: 1px solid #5e5e5e;
        border-radius: 5px;
        margin: 5px;
        font-style: normal;
        font-weight: 900;
        background: rgba(213, 213, 213, 0.5);
        color: #5e5e5e;
        padding: 6px 15px 4px 15px;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .v2-subscription-container {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0 !important;
    }

    // .disabled:hover {
    //   background-color: red !important;
    // }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }

    .first-item {
      width: 55%;
    }

    .second-item {
      width: 21%;
    }

    .third-item {
      width: 21%;
    }

    .header-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr {
        background: #000;
        color: rgba(255, 255, 255, 1);
        // background: rgba(102, 219, 196, 0.8);
      }

      th:first-child {
        text-align: left;
      }

      th {
        border: 1px solid #9ca1a8;
        padding: 6px 7px 5px 7px;
      }
    }

    .dark-header-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr {
        border: 1px solid #9ca1a8;
        background: #303742;
        color: rgba(255, 255, 255, 1);
        // background: rgba(102, 219, 196, 0.8);
      }

      th:first-child {
        text-align: left;
      }

      th {
        border: 1px solid #9ca1a8;
        padding: 6px 7px 5px 7px;
      }
    }

    .body-table {
      width: 100%;
      font-size: 12px;

      td:first-child {
        text-align: left;
        font-weight: 500;
      }

      td {
        background: rgba(29, 29, 78, 0.1);
        text-align: center;
        border: 1px solid #ffffff;
        padding: 3px 9px;
      }

      td:nth-child(1) {
        color: #5e5e5e !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }

      td:nth-child(2),
      td:nth-child(3) {
        color: #8989a1 !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }
    }

    .footer-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr:first-child {
        background: #000;
        color: #ffffff;
      }

      tr:last-child td {
        background: transparent !important;
      }

      th:first-child {
        text-align: left;
      }

      td {
        text-align: center;
      }

      th,
      td {
        border: 1px solid #9ca1a8;
        padding: 6px 7px 5px 7px;
      }
    }
  }

  // Account Settings Drawer CSS End

  //Ad Style Start
  .sticky-container {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    bottom: 0;
  }

  .ad-container {
    height: auto;
    width: auto;
    min-height: 90px;
    margin: 0px 0 0px 0;
    text-align-last: center;
    // max-width: 728px;
    // max-height: 90px;
    // min-width: 468px;
    // min-height: 60px;
    // width: 100%;
    // width: 720px;
    // height: 100px;
    // background: #000000;
  }

  .ad-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  //Ad Style End

  .loading-icon {
    margin-left: 10px;
    color: black;
  }

  .delete-dialog-btn {
    svg {
      fill: white !important;
    }
  }
}

.v2-drawer-article {
  .ant-drawer-body {
    overflow: hidden !important;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    margin-top: 10px;
  }
  .app-selector-list-container {
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0px solid #d5d5d5 !important;
      margin: 0 0 -7px 21px !important;
      width: 86%;
    }

    .ant-tabs-tab-btn {
      color: #1d1d4b;
    }

    .ant-tabs-tab {
      background-color: white !important;
      border-radius: 15px !important;
      border: 1px solid #d5d5d5 !important;
      color: #1d1d4b !important;
      height: 30px !important;
      margin-right: 10px !important;
      margin-top: 10px;
    }

    .ant-tabs-tab:hover {
      background-color: #d5d5d5 !important;
      color: #1d1d4b !important;
    }

    // .ant-tabs-tab-active {
    //   background-color: #303742 !important;
    //   color: #fff !important;

    //   .ant-tabs-tab-btn {
    //     color: #fff !important;
    //     text-align: center !important;
    //     font-size: 14px !important;
    //     font-style: normal !important;
    //     font-weight: 500 !important;
    //     line-height: 16px !important;
    //     /* 100% */
    //   }
    // }
  }

  .ant-drawer-body {
    padding: 0px !important;
    margin: 0px !important;
  }

  .ant-drawer-mask {
    background: transparent !important;
  }

  .ant-drawer-header {
    padding: 34px 0 10px 0 !important;
    border-bottom: 0px !important;
  }

  //Scroll Bar Style Start
  a {
    color: #00deab !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.5) !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    // background: #555 !important;
    background: #d9d9d9 !important;
  }

  //Scroll Bar Style End
  .ads-div {
    text-align: -webkit-right;
  }

  // .ant-drawer-wrapper-body {
  //   padding: 0px 50px 0px 50px !important;
  // }
  .ant-drawer-wrapper-body {
    padding: 0px 50px 0px 50px !important;
  }

  // Article List Drawer CSS Start

  .ant-drawer-content {
    background-color: #f8f8f9 !important;
  }

  .article-list-container {
    -webkit-font-smoothing: auto !important;
    margin-bottom: 30px;
    .results {
      color: #3c3c3c;
      text-align: right;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px; /* 76.923% */
      width: 96.5%;
      margin-bottom: 15px;
    }

    .unpaid-app-container {
      width: 895px;
      height: 98px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px dashed #ff644e;
      background: #fff;
      padding: 20px;
      .unpaid-app-msg {
        color: #000;
        font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 100% */
        span {
          color: #000;
          font-family: "Noto Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 18px;
        }
      }

      .unpaid-botton-contain {
        display: flex;
        margin-top: 15px;

        .unpaid-buy-now-btn {
          cursor: pointer;
          width: 141px;
          height: 24px;
          flex-shrink: 0;
          border-radius: 4px;
          border: 1px solid #ff644e;
          background: #ff644e;
          color: #fff;
          font-family: Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 100% */
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .unpaid-para {
          margin-left: 7px;
          color: #454e5c;
          font-family: "Noto Sans";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          line-height: 15px; /* 100% */

          span {
            font-weight: 700 !important;
          }
        }
      }
    }

    .article-list-item {
      background-color: white;
      margin-top: 7px;
      padding: 10px;
      padding: 10px;
      margin-right: 20px;
      border-radius: 4px;

      .time-div {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        // width: 1000%;
        /* 116.667% */
      }

      .article-list-title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .breaking-tag {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
        background-color: #ff644e;
        cursor: pointer;
        // width: 70px;
        border-radius: 0px;
        padding: 1px 4px 2px 4px;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0px solid #d5d5d5 !important;
      margin: 0 0 15px 0px !important;
    }

    .ant-tabs-tab {
      background-color: white !important;
      border-radius: 15px !important;
      border: 1px solid #d5d5d5 !important;
      color: #1d1d4b;
      height: 30px !important;
      margin-right: 10px !important;
    }

    .ant-tabs-tab:hover {
      background-color: #d5d5d5 !important;
      color: black;
    }

    // .ant-tabs-tab-active {
    //   background-color: #303742 !important;

    //   .ant-tabs-tab-btn {
    //     color: #fff !important;
    //     text-align: center !important;
    //     font-size: 14px !important;
    //     font-style: normal !important;
    //     font-weight: 400 !important;
    //     line-height: 16px !important;
    //     /* 100% */
    //   }
    // }

    .list-pagination {
      margin: 20px 20px 0 0;

      button {
        color: black !important;
      }

      li {
        a {
          color: black !important;
        }
      }

      .ant-pagination-item {
        opacity: 0.5;
        border-color: #d9d9d9 !important;
      }

      .ant-pagination-item-active {
        a {
          background-color: #1d1d4b !important;
          color: white !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      background: black !important;
      height: 4px !important;
    }

    .article-list-heading {
      text-align: right;
    }

    .bookmark-icon {
      width: 12px;
      height: auto;
    }

    .refresh-icon {
      width: 14px;
      margin-right: 20px;
      height: auto;
    }

    .bookmark-icon.link {
      margin-right: 10px;
    }

    .share-icon {
      width: 14px;
      height: auto;
      cursor: pointer;
    }
  }

  // Article List Drawer CSS End
  .next-details-buttons {
    display: flex;
    width: 97.5%;
    justify-content: space-between;
    margin-bottom: 10px;
    .next {
      transform: rotate(180deg);
      cursor: pointer;
    }
    .previous {
      cursor: pointer;
    }
  }
  // Article Detail/Reuters Detail Drawer CSS End
  .article-detail-container {
    -webkit-font-smoothing: auto !important;
    background-color: white !important;
    padding: 25px 50px 25px 50px;
    margin-right: 20px;
    img {
      width: -webkit-fill-available !important;
      height: auto !important;
    }

    .breadcrumb {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
      /* 100% */
      letter-spacing: 0.7px;
    }

    .article-detail-title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1.2px;
      margin-top: 10px;
    }

    .article-detail-text {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      img {
        padding-right: 0px !important;
      }
    }

    .article-detail-date {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.7px;
      margin-top: 20px;
      text-align-last: left;
    }

    .share-icon {
      width: 14px;
      height: auto;
      cursor: pointer;
    }

    .related-articles {
      margin-top: 15px;

      .article-detail-card {
        position: relative;
        text-align: center;
        cursor: pointer;

        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
          max-height: 140px;
          object-fit: contain;
          opacity: 0.4;
          background: rgba(0, 0, 0, 0.5);
        }

        .article-detail-image-center-text {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80%;
          font-weight: 900;
          color: white;
          transform: translate(-50%, -50%);
        }
      }

      .article-detail-image-container {
        margin: 5px;
        display: block;
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }

  // Article Detail/Reuters Detail Drawer CSS End

  // Unlock New Feature Drawer CSS Start
  .unlock-feature-table {
    width: 100%;
    font-size: 10px;

    tr:first-child {
      background: #1d1d4b;
      color: #ffffff;
    }

    tr:last-child td {
      background: #ffffff !important;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      width: 37%;
      font-weight: 500;
    }

    td {
      background: rgba(29, 29, 78, 0.1);
      text-align: center;
    }

    td,
    th {
      border: 1px solid #ffffff;
      padding: 3px 7px;
      width: 21%;
    }
  }

  .feature-list-table {
    width: 100%;
    font-size: 11px;

    tr:first-child {
      background: rgba(102, 219, 196, 0.8);
    }

    th:first-child,
    td:first-child {
      text-align: left;
      width: 37%;
      font-weight: 500;
    }

    td {
      background: rgba(29, 29, 78, 0.1);
      text-align: center;
    }

    td,
    th {
      border: 1px solid #ffffff;
      padding: 3px 7px;
      width: 21%;
    }
  }

  .add-feature-btn {
    margin: 5px 0;
    width: 40%;
    margin-left: auto;
  }

  .add-feature-note {
    margin: 5px 0;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
  }

  // Unlock New Feature Drawer CSS End

  // General Settings Drawer CSS Start
  .sidebar-color-choose-tile {
    width: 15px;
    height: 12px;
    border: 1px solid #5e5e5e;
    border-radius: 2px;
  }

  .sidebar-color-choose,
  .dark-mode-switch {
    margin: 5px 10px;
  }

  .ant-switch-checked {
    background-color: #8989a1 !important;
  }

  .ant-switch:focus {
    box-shadow: none !important;
  }

  .ant-switch-checked:focus {
    box-shadow: none !important;
  }

  // General Settings Drawer CSS End

  // Account Settings Drawer CSS Start
  .v2-account-settings-drawer {
    position: relative;
    margin-right: 10px;

    .ant-picker {
      padding: 0 11px 0 0 !important;
    }

    .ant-picker-input > input {
      padding: 4px 11px !important;
    }

    .profile-update-form {
      margin-left: 10px;
    }

    .personal-form-topic {
      color: #454e5c !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      margin: 5px 0 !important;
      padding-left: 10px !important;
    }

    .dark-personal-form-topic {
      color: #9ca1a8 !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      margin: 5px 0 !important;
      padding-left: 10px !important;
    }

    .input-field-email {
      border-radius: 5px !important;
      background: rgba(233, 233, 237, 0.5) !important;
      color: #5e5e5e !important;
      border: none !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 12px !important;
      padding: 9px !important;
    }

    .dark-input-field-email {
      border-radius: 5px !important;
      background: #4c5563 !important;
      color: #9ca1a8 !important;
      border: none !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 12px !important;
      padding: 9px !important;
    }

    .change-email,
    .change-password {
      margin: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px !important;
    }

    .profile-heading {
      font-weight: 500;
      margin-bottom: 5px;
      text-align-last: start;
    }

    .profile-heading-email {
      font-weight: 700;
      margin-bottom: 5px;
      text-align-last: end;
    }

    .profile-sub-title {
      font-weight: 700;
      padding-left: 10px;
    }

    .profile-sub-text {
      padding-left: 30px;
    }

    .user-update-btn {
      margin: 0 0 10px 0;
      width: 60%;
      margin-left: auto;
    }

    .profile-btn-div {
      margin: 5px 0;
      width: 55%;
      margin-left: auto;
    }

    .profile-last-div {
      text-align: right;
      font-size: 10px;
      font-weight: 600;
    }

    .confirm-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: white;
      padding: 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;

      .confirm-dialog-text {
        font-weight: 500;
        color: #ff644e;
      }

      .delete-dialog-btn {
        background: #ff644e80;
        padding: 6px 15px 4px 15px;
        font-size: 10px;
        line-height: 10px;
        border: 1px solid #5e5e5e;
        border-radius: 5px;
        margin: 5px;
        font-style: normal;
        font-weight: 900;
        color: #5e5e5e;
      }
    }

    .enter-password-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;

      .enter-password-dialog-text {
        font-weight: 500;
      }

      .submit-btn-div {
        text-align: center;

        .submit-dialog-btn {
          border: 1px solid #5e5e5e;
          border-radius: 5px;
          margin: 5px;
          font-style: normal;
          font-weight: 900;
          background: rgba(213, 213, 213, 0.5);
          color: #5e5e5e;
          padding: 6px 15px 4px 15px;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }

    .continue-dialog {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px 15px;
      box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
      border-radius: 4px;
      text-align: center;

      .continue-dialog-text {
        font-weight: 500;
        margin-bottom: 5px;
      }

      .submit-dialog-btn {
        border: 1px solid #5e5e5e;
        border-radius: 5px;
        margin: 5px;
        font-style: normal;
        font-weight: 900;
        background: rgba(213, 213, 213, 0.5);
        color: #5e5e5e;
        padding: 6px 15px 4px 15px;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .v2-subscription-container {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }

    .first-item {
      color: #5e5e5e;
      width: 50%;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }

    .second-item {
      width: 25%;
      color: #8989a1;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }

    .third-item {
      width: 25%;
      color: #8989a1;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }

    .header-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr {
        background: #000;
        color: rgba(255, 255, 255, 1);
        border: 1px solid #9ca1a8;

        // background: rgba(102, 219, 196, 0.8);
      }

      th {
        border: 1px solid #9ca1a8;
      }

      th:first-child {
        text-align: left;
      }

      th {
        border: 1px solid #9ca1a8;
        padding: 6px 7px 5px 7px;
      }
    }

    .dark-header-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr {
        border: 1px solid #9ca1a8;
        background: #303742;
        color: rgba(255, 255, 255, 1);
        // background: rgba(102, 219, 196, 0.8);
      }

      th:first-child {
        text-align: left;
      }

      th {
        border: 1px solid #9ca1a8;
        padding: 6px 7px 5px 7px;
      }
    }

    .body-table {
      width: 100%;
      font-size: 12px;

      td:first-child {
        text-align: left;
        font-weight: 500;
      }

      td {
        background: rgba(29, 29, 78, 0.1);
        text-align: center;
        border: 1px solid #9ca1a8;
        padding: 3px 9px;
      }

      td:nth-child(1) {
        color: #5e5e5e !important;
      }

      td:nth-child(2),
      td:nth-child(3) {
        color: #8989a1 !important;
      }
    }

    .footer-table {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;

      tr:first-child {
        background: #000;
        color: #ffffff;
      }

      tr:last-child td {
        background: transparent !important;
      }

      th:first-child {
        text-align: left;
      }

      td {
        text-align: center;
      }

      th,
      td {
        border: 1px solid #ffffff;
        padding: 6px 7px 5px 7px;
      }
    }
  }

  // Account Settings Drawer CSS End

  //Ad Style Start
  .sticky-container {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    bottom: 0;
  }

  .ad-container {
    height: auto;
    width: auto;
    min-height: 90px;
    margin: 0px 0 0px 0;
    text-align-last: center;
    // max-width: 728px;
    // max-height: 90px;
    // min-width: 468px;
    // min-height: 60px;
    // width: 100%;
    // width: 720px;
    // height: 100px;
    // background: #000000;
  }

  .ad-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  //Ad Style End

  .loading-icon {
    margin-left: 10px;
    color: black;
  }
}

.v2-drawer-email-me {
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    background: #f8f8f9 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-drawer-wrapper-body {
    background: #f8f8f9 !important;
  }

  .email-me-detail-container {
    width: 100%;
    height: 100vh;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    // margin: 15px auto;
  }

  .close-icon {
    text-align: right;

    img {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      margin-top: 25px;
      margin-right: 25px;
      cursor: pointer;
    }
  }

  .email-me-topic {
    width: 60%;
    margin: 10px auto;

    div:nth-child(1) {
      color: #000;
      text-align: center;
      font-family: Helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
    }

    div:nth-child(2) {
      color: #000;
      font-family: Helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      text-align: center;
    }
  }

  .email-me-banner-img {
    margin-top: 10px;

    img {
      width: 100%;
    }
  }

  .email-btn {
    color: #fff;
    text-align: center;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #4f48e2;
    border: none;
    margin: auto;
    padding: 8px 20px;
  }

  .email-me-btn-container {
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: 10px;
  }
}

.app-selector-drawer-main-img {
  margin-bottom: -10px !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #5e616e !important;
}

.section-tag {
  color: #fff;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  // line-height: 10px;
  // width: 70px;
  border-radius: 0px;
  padding: 1px 4px 2px 4px;
  vertical-align: middle;
  margin-right: 5px;
  // margin-bottom: 5px;
  margin-top: 3px;
}

.stock-tag-name {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 9px; /* 100% */
  // padding: 10px !important;
  padding: 5px 10px !important;
}

.section-imp-tag {
  display: flex;
  align-items: center;
}

// .ant-tabs-tab-btn {
//   color: #5e5e5e !important;
//   text-shadow: none !important;
// }
.ant-tabs-tab-active {
  // color: #5e5e5e !important;
  // border-bottom: 2px solid #5e5e5e !important;
}

// .single_premium {
//   .premium-select-title {
//     color: #5e5e5e !important;
//   }
// }
.limited-class {
  .ant-modal-close-x {
    width: 100% !important;
    height: 100% !important;
    padding: 51px 55px !important;
  }
}
.limit-exceed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 93px 0 0 0;
  div {
    font-family: Helvetica !important;
  }

  .title {
    font-family: "Helvetica" !important;
    font-weight: 700;
    font-size: 32px;
    line-height: 31px;
    margin-bottom: 20px;
  }

  .limit-exceed-article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica";
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    font-family: Helvetica !important;
    span {
      font-weight: 700;
      font-family: Helvetica !important;
    }
  }
  .singin-btn {
    width: 179px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    margin: 0 -5px;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
      height: 16.056px;
    }

    .singin-text {
      color: #1f1f1f;
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      margin-left: 5px;
    }
  }

  .singin-btn:hover {
    background: #d0cfcf;
  }
}

.list-container-article-viewer {
  .unpaid-app-container {
    margin-top: 3px;
    width: auto;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px dashed #ff644e;
    background: #fff;
    padding: 7px 13px 11px 10px;
    .unpaid-app-msg {
      color: #000;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 100% */
      span {
        color: #000;
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 18px;
      }
    }

    .unpaid-botton-contain {
      display: flex;
      margin-top: 5px;
      flex-wrap: wrap;
      .unpaid-buy-now-btn {
        cursor: pointer;
        width: 104px;
        height: 24px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #ff644e;
        background: #ff644e;
        color: #fff;
        font-family: Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 100% */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-right: 5px;
      }

      .unpaid-para {
        color: #454e5c;
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        // display: flex;
        align-items: center;
        line-height: 12px; /* 100% */
        width: 200px;
        margin-top: 5px;
        span {
          font-weight: 700 !important;
        }
      }
    }
  }
}

// search box nav bar header
.header_nav {
  // font-size: 200px;
  width: 100%;
  height: 39px;
  flex-shrink: 0;
  background: #fff;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 8px;
  gap: 16px;

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .bookmark-container {
    border: 1px solid red;
    width: 30px;
    aspect-ratio: 1/1;
  }

  .bookmark_container img {
  }

  .search_box {
    height: 39px;

    display: flex;
    justify-content: end;
    // margin-right: 20px;
    .group {
      display: flex;
      line-height: 28px;
      align-items: center;
      position: relative;
    }

    .disabled {
      cursor: pointer !important;
    }

    .input {
      height: 30px;
      line-height: 28px;
      padding: 0 7.22px;
      width: 252px;
      padding-left: 33.09px;
      border-radius: 4px;
      border: 1px solid #d5d5d5;
      background: #f4f4f6;
      border-radius: 4px;
      outline: none;
      color: #0d0c22;
      transition: 0.3s ease;
      caret-color: #37aee2;
    }

    .input:focus,
    .input_focused {
      width: 450px;
      border: 1px solid #37aee2;
    }

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      background-image: url("../images/close_search_icon_small.svg");
      background-position: center;
      background-size: cover;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    .input::placeholder {
      color: #9b9b9b;
      font-family: "Noto Sans";
      font-size: 13.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 140.741% */
    }

    .icon {
      position: absolute;
      left: 11.09px;
      width: 12px;
      height: 12px;
    }
  }
}
.search_list_container::-webkit-scrollbar {
  display: none;
}

.search_list_container,
.bookmark_list_container {
  position: absolute;
  top: 52px;
  right: 14px;
  width: 529px;
  height: 573px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.12);
  z-index: 999999;
  padding: 26px 26px 29px 25px;
  overflow-y: auto;
  scrollbar-width: none; /* Hides the scrollbar */
  -ms-overflow-style: none;
  .results {
    color: #3c3c3c;
    text-align: right;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px; /* 76.923% */
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 999;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .closeIcon {
    width: 20px;
    cursor: pointer;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    img {
      height: 100%;
      margin-left: auto;
      width: 20px;
    }
  }

  .topics {
    color: #000;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 138.462% */
    margin-top: 12px;
  }

  .card_container {
    display: flex;
    width: 100%;
    padding: 13px 8px;

    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;
    gap: 7px;
    flex-wrap: wrap;

    transition: all 200ms ease;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }

    .card_top_container {
      display: flex;
      width: 100%;
      .left {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }
      .title_img {
        display: flex;
        align-items: center;
        margin-top: 5px;
        img {
          width: 28px;
          height: 28px;
          border-radius: 4px;
          object-fit: contain;
        }
      }
      .right {
        margin-left: 7px;
        width: 100%;

        .source_topic {
          color: #1d1d4b;
          font-family: "Helvetica Neue", sans-serif !important;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 15px; /* 136.364% */
          text-transform: capitalize;
        }

        .date_time {
          color: #1d1d4b;
          font-family: "Helvetica Neue", sans-serif !important;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 10px; /* 100% */
        }
      }
    }

    .main_title {
      color: #000;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 138.462% */
      cursor: pointer;

      p {
        margin-bottom: 0 !important;
      }
    }

    .second_title {
      color: #000;
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      cursor: pointer;
    }
  }
}

//search box for feed

.search_feed_container {
  position: absolute;
  width: calc(100% - 4px);
  margin-left: 4px;
}

.searchbox_feed {
  margin-inline: auto;
  // position: absolute;
  width: 100%;
  left: 0;
  z-index: 99;

  .search_box {
    .group {
      display: flex;
      line-height: 28px;
      align-items: center;
      width: 100%;
    }

    .input {
      height: 25px;
      line-height: 28px;
      padding: 0 7.22px;
      padding-left: 30px;
      border-right: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      border-left: 1px solid #dadada;
      border-top: none;
      background: #f9f9f9;
      outline: none;
      color: #0d0c22;
      width: 100%;
      // position: absolute;
      // right: -4px;
      top: 0;
      left: 0;
      z-index: 99;
      caret-color: #37aee2;
      border-radius: 0px 0px 2px 2px;
      font-size: 11px;
    }
    .input:focus {
      border: 1px solid #37aee2;
    }

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      background-image: url("../images/close_search_icon_small.svg");
      background-position: center;
      background-size: cover;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    .input::placeholder {
      color: #9b9b9b;
      font-family: "Helvetica";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 172.727% */
    }

    .icon {
      position: absolute;
      left: 14px;
      width: 12px;
      height: 12px;
      z-index: 1000;
      top: 6px;
    }
  }
}
//search box for article

.searchbox_article {
  .search_box {
    .group {
      display: flex;
      line-height: 28px;
      align-items: center;
      position: relative;
      width: 98%;
      margin-bottom: 23px;
      margin-top: 15px;
    }

    .disabled {
      cursor: pointer !important;
    }

    .input {
      height: 42px;
      line-height: 28px;
      padding: 0 7.22px;
      padding-left: 39px;
      border-radius: 4px;
      border: 1px solid #dadada;
      background: #fff;
      outline: none;
      color: #0d0c22;
      width: 100%;
      caret-color: #37aee2;
      font-size: 16px;
    }
    .input:focus {
      border: 1px solid #37aee2;
    }

    .clear_btn {
      width: 94px;
      height: 42px;
      flex-shrink: 0;
      border-radius: 0px 4px 4px 0px;
      border-top: 1px solid #37aee2;

      border-right: 1px solid #37aee2;

      border-bottom: 1px solid #37aee2;

      background: #f4f4f6;
      position: absolute;
      z-index: 999;
      right: 0;
      color: #3c3c3c !important;
      text-align: center;
      font-family: "Helvetica" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px; /* 71.429% */
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: 0.3s ease-in;
      cursor: pointer;
    }

    .not_focused {
      display: none;
    }

    .input::placeholder {
      color: #9b9b9b;

      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 118.75% */
    }

    .icon {
      position: absolute;
      left: 14px;
      width: 16.5px;
      height: 16.5px;
    }
  }
}

.without_search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;

  .para_first {
    color: #1d1d4b;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
  }

  .para_second {
    color: #000;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
    width: 362px;
  }
}

.highlighted_search_text {
  background: yellow;
}

.loading_card_container {
  width: 103%;
  padding: 8px;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: 7px;
  flex-wrap: wrap;
  height: 71px;
  margin-top: 22px;

  .loading_card_top_container {
    display: flex;
    .dummy_img {
      width: 37.729px;
      height: 28px;
      flex-shrink: 0;
      border-radius: 4px;
      background: linear-gradient(
        91deg,
        #f1efef 0.98%,
        #f9f8f8 52.84%,
        #e7e5e5 97.83%
      );
    }
    .right {
      margin-left: 7px;

      .source_topic {
        width: 215.593px;
        height: 14px;
        flex-shrink: 0;
        background: linear-gradient(
          90deg,
          #f1efef 1.86%,
          #f9f8f8 48.56%,
          #e7e5e5 99.99%
        );
      }

      .date_time {
        width: 246.585px;
        height: 9px;
        flex-shrink: 0;
        background: linear-gradient(
          90deg,
          #f1efef 0%,
          #f9f8f8 53.13%,
          #e7e5e5 97.43%
        );
        margin-top: 3px;
      }
    }
  }

  .left {
    display: flex;
    width: 100%;
    justify-content: end;
    .box {
      width: 16.169px;
      height: 12px;
      flex-shrink: 0;
      border-radius: 2px;
      background: linear-gradient(
        91deg,
        #f1efef -6.95%,
        #f9f8f8 49.24%,
        #e7e5e5 97.98%
      );
      margin-left: 4.04px;
    }
  }

  .loading_main_title {
    width: 477px;
    height: 34px;
    flex-shrink: 0;
    background: linear-gradient(
      90deg,
      #f1efef 3.02%,
      #f9f8f8 45.54%,
      #e7e5e5 97.13%
    );
    margin-top: 9px;
  }

  .second_title {
    color: #000;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
  }
}

.feed_without_search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  .para_first {
    color: #1d1d4b;
    margin-top: 10px;
    text-align: center;
    font-family: "Helvetica";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .para_second {
    color: #000;
    margin-top: 6px;
    text-align: center;
    font-family: "Helvetica";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

// for custom feed

.custom-feed-main-container {
  display: flex;
  height: 27px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1px;
  width: 160px;
  cursor: pointer;
  margin: 20px;
  border: 0.4px solid var(--Brand-Teal-Color, #00ddc0);
  background: #efffff;
  .feed-text {
    color: #343459;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.feed-name-edit-main-container {
  input {
    display: flex;
    width: 96%;
    height: 19px;
    padding: 8px 4px;
    align-items: center;
    gap: 6px;
    border-radius: 1px;
    border: 0.6px solid #d5d5d5;
    background: #fff;
    color: #3c3b3b;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 190% */
  }
}

.custom-add-feed-main-container {
  .ant-checkbox-group {
    height: 209px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
  }

  .ant-checkbox-group::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }
  .feed-lists {
    .ant-checkbox + span {
      padding-left: 8px !important;
      color: #5e5e5e;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 120% */
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .ant-checkbox-wrapper {
      margin-top: 2px;
    }

    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
      // background-color: #fff;
      border: 1px solid #d9e1e7;
      border-radius: 3px;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
      border: 1px solid #343459;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border: 1px solid #343459;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #343459;
      border-color: #343459;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #343459;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #343459;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-radius: 1px;
      width: 4px;
      height: 7px;
      margin-top: -0.8px;
    }
  }

  .ant-btn-primary {
    color: #fff;
    border-radius: 1px;
    border: 1px rgba(31, 196, 174, 0.4);
    background: #343459;
    margin-top: 10px;
    /* width: 20px; */
    width: 44px;
    height: 19px;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding: 0;
  }

  .ant-btn-primary:hover {
    border-color: #343459;
    background: #343459;
  }
  .ant-btn-primary:active {
    border-color: #343459;
    background: #343459;
  }

  .ant-btn-primary {
    border-color: #343459;
    background: #343459;
  }
  .ant-btn-primary:focus {
    border-color: #343459;
    background: #343459;
  }
  .ant-btn[disabled] {
    color: #fff !important;
    border-color: #343459 !important;
    background: #343459 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
}

.social_share_container {
  justify-content: end;
  gap: 6px;
  display: flex;
  align-items: center;

  .social_bookmark_container {
    cursor: pointer;
    width: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.bookmark_list_container_overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 99999;
  left: 0;
  top: 0;
}

.bookmark_list_container {
  position: fixed;
  top: 52px;
  right: 15px;
  width: 529px;
  height: 422px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.12);
  z-index: 999999;
  padding: 26px 26px 29px 25px;
  overflow-y: auto;
  scrollbar-width: none; /* Hides the scrollbar */
  -ms-overflow-style: none;

  .bookmark_wrapper {
    height: 100%;
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
  }

  .card_container {
    // &:hover {
    //   background: #e9e9ed;
    // }

    img {
      height: 12px;
      width: 12px;
      object-fit: contain;
    }
  }

  .not_found_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1,
    p {
      margin-bottom: 0px !important;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      max-width: 40ch;
      text-align: center;
      margin-top: 2px;
    }

    .explore {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #4880ff;
      cursor: pointer;
      margin-top: 11px;
      font-size: 15px;
    }

    .explore img {
      height: 15px;
      width: 12px;
    }
  }
}

.custom_cross_btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
  width: 10px;
  aspect-ratio: 1/1;
}

.custom-feed-modal-delete {
  top: 50% !important;
  left: 50% !important;
  translate: -50% -50% !important;
  // transform: translate(-50%, -50%) !important;
  .ant-modal {
  }
  .ant-modal-body {
    padding: 10px 21px 12px 22px !important;
  }
  .logout-btn {
    padding: 8px 17px !important;
  }

  .logout-modal-title {
    margin: 0 0 12px 0 !important;
    color: #000;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}
