@use "../../../style/scss/vars.scss" as vars;

.otp-input-container {
  font-family: inherit;

  input {
    border: 1px solid vars.$input-border;
    box-shadow: 0px 1px 4px 0px #19213d14;
    border-radius: vars.$radius-md;
  }

  input.focused {
    border: 1px solid vars.$primary-0;
  }

  &.error {
    input.focused {
      border: 1px solid vars.$error-0;
    }
  }
}
