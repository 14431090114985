@use "../../../../style/scss/vars.scss" as vars;

.applied-promo-card {
  border: 1px solid red;
  padding: 12px 11px !important;
  border-radius: vars.$radius-md;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid vars.$success;
  background: #eefff0;
  max-width: 264px;

  svg {
    color: vars.$success;
  }

  p {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: vars.$fs-xs;

    span {
      color: #858585;
    }
  }

  strong {
    font-weight: vars.$fw-medium;
  }
}
