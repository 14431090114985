.customFilter-container {
  position: relative;
  // top: 22.5px;
  input.toggle-checkbox {
    position: absolute;
    z-index: -1;
    &:focus {
      & ~ label {
        // border: 1px solid red;
      }
    }
  }

  color: #3c3b3b;

  & > label {
    display: flex;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    padding: 5px;
    border-radius: 2px;
    color: #17181c;
    border: 1px solid #a9adbc;
    height: 24px;
    width: 120px;
    margin-top: 2px;
    cursor: pointer;

    span {
      flex: 1;
    }

    svg {
      color: #989898;
    }

    & > * {
      pointer-events: none;
    }

    &:hover {
      // background: #f2f2f4;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
  }
  .options-container {
    position: absolute;
    z-index: 9999;
    background: white;
    top: calc(100% + 4px);
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    left: 0;
    overflow-y: auto;
    max-height: 150px;

    .option {
      display: flex;
      align-items: center;
      height: 30px;
      gap: 6px;
      cursor: pointer;
      padding: 9px 6px;

      &:hover {
        background: #f2f2f4;
      }

      input[type="checkbox"] {
        height: 12px;
        width: 14px;
        border-radius: 3px;
        position: relative;
        border: none;
        appearance: none;
        border: 1px solid #d9e1e7;

        &:checked {
          appearance: revert;
          accent-color: #343459;
          border-radius: 3px;
        }
      }

      label.name {
        width: 100%;
        text-transform: capitalize;
        cursor: pointer;
        color: #000;
        font-size: 10px;
      }
    }
  }
}

.customFilter-container.dark {
  & > label {
    margin-top: 2px;
    color: #f1f1f4;
    border: 1px solid #9ba0b0;
    // background: #303742;

    &:hover {
      background: #384251;
    }
  }

  .options-container {
    border: 1px solid #9ca1a8;
    background: #303742;
    .option {
      background: #303742;
      color: #9b9b9b;

      &:hover {
        background: #3c434d;
      }

      input[type="checkbox"] {
        background: #515a67;
        border: 1px solid #636b75;
        &:checked {
          accent-color: #515a67;
        }
      }

      label.name {
        color: #fff;
      }
    }
  }
}
