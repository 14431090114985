@import "./layout.scss";
@import "./reset.scss";

body {
  font-family: $title-font;
  color: var(--text-color);
  font-size: 14px;
  // background: url("/src/assets/home_image/bgpat.jpg") repeat fixed;
  background-size: 6%;

  // div,
  // p {
  //   color: var(--text-color);
  // }

  // &::before {
  //   //content: '';
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background-size: 6%;
  //   filter: brightness(0.6);
  // }
}

a {
  text-decoration: none;
  color: var(--light-color);

  &:hover {
    color: var(--primary-color);
  }
}

a,
button {
  cursor: pointer;
}

.logo {
  font-size: 2.4rem;
  font-weight: bolder;
}

.logo-mini {
  width: 24px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
}

.logo-mini-white {
  width: 24px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    filter: brightness(0) invert(1);
  }
}

.logo-mini-black {
  width: 24px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    filter: brightness(0);
  }
}

.btn {
  padding: $sm $lg;
  border-radius: $sm;
  background: rgba(0, 0, 0, 0.12);
  border: none;
  color: var(--text-color);
  font-family: $title-font;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  font-weight: bold;
  text-align: center;

  &:active {
    transform: translateY(1px);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &.primary {
    background: var(--primary-color);
    color: var(--light-color);

    &:disabled {
      background: rgb(40, 40, 40);
      border: 1px solid var(--primary-color);
    }
  }

  &.secondary {
    background: var(--secondary-color);
    color: var(--light-color);
  }

  &.light {
    background: var(--light-color);
    color: var(--primary-color);
  }

  &.success {
    background: $success-color;
    color: var(--light-color);
  }

  &.danger {
    background: $danger-color;
    color: var(--light-color);
  }

  &.info {
    background: $info-color;
    color: var(--light-color);
  }

  &.warning {
    background: $warning-color;
    color: var(--light-color);
  }

  &.outlined.primary {
    border: 1px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);

    &:hover {
      background: var(--primary-color);
      color: var(--light-color);
    }
  }

  &.outlined.secondary {
    border: 1px solid var(--secondary-color);
    background: transparent;
    color: var(--secondary-color);

    &:hover {
      background: var(--secondary-color);
      color: var(--light-color);
    }
  }

  &.outlined.light {
    border: 1px solid var(--light-color);
    background: transparent;
    color: var(--light-color);

    &:hover {
      background: var(--light-color);
      color: var(--light-color);
    }
  }

  &.outlined.success {
    border: 1px solid $success-color;
    background: transparent;
    color: $success-color;

    &:hover {
      background: $success-color;
      color: var(--light-color);
    }
  }

  &.outlined.danger {
    border: 1px solid $danger-color;
    background: transparent;
    color: $danger-color;

    &:hover {
      background: $danger-color;
      color: var(--light-color);
    }
  }

  &.outlined.info {
    border: 1px solid $info-color;
    background: transparent;
    color: $info-color;

    &:hover {
      background: $info-color;
      color: var(--light-color);
    }
  }

  &.outlined.warning {
    border: 1px solid $warning-color;
    background: transparent;
    color: $warning-color;

    &:hover {
      background: $warning-color;
      color: var(--light-color);
    }
  }

  &.rounded {
    border-radius: 300px;
  }
}

.chip {
  padding: $xs $md;
  border-radius: 300px;
  background: rgba(0, 0, 0, 0.12);
  border: none;
  color: var(--text-color);
  font-family: $title-font;

  &:active {
    transform: translateY(1px);
  }

  &.dark {
    background: var(--text-color);
    color: var(--light-color);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &.primary {
    background: var(--primary-color);
    color: var(--light-color);
  }

  &.secondary {
    background: var(--secondary-color);
    color: var(--light-color);
  }

  &.light {
    background: var(--light-color);
    color: var(--primary-color);
  }

  &.success {
    background: $success-color;
    color: var(--light-color);
  }

  &.danger {
    background: $danger-color;
    color: var(--light-color);
  }

  &.info {
    background: $info-color;
    color: var(--light-color);
  }

  &.warning {
    background: $warning-color;
    color: var(--light-color);
  }

  &.outlined.primary {
    border: 1px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);

    &:hover {
      background: var(--dark-primary);
      color: var(--light-color);
    }
  }

  &.outlined.secondary {
    border: 1px solid var(--secondary-color);
    background: transparent;
    color: var(--secondary-color);

    &:hover {
      background: var(--secondary-color);
      color: var(--light-color);
    }
  }

  &.outlined.light {
    border: 1px solid var(--light-color);
    background: transparent;
    color: var(--light-color);

    &:hover {
      background: var(--light-color);
      color: var(--light-color);
    }
  }

  &.outlined.success {
    border: 1px solid $success-color;
    background: transparent;
    color: $success-color;

    &:hover {
      background: $success-color;
      color: var(--light-color);
    }
  }

  &.outlined.danger {
    border: 1px solid $danger-color;
    background: transparent;
    color: $danger-color;

    &:hover {
      background: $danger-color;
      color: var(--light-color);
    }
  }

  &.outlined.info {
    border: 1px solid $info-color;
    background: transparent;
    color: $info-color;

    &:hover {
      background: $info-color;
      color: var(--light-color);
    }
  }

  &.outlined.warning {
    border: 1px solid $warning-color;
    background: transparent;
    color: $warning-color;

    &:hover {
      background: $warning-color;
      color: var(--light-color);
    }
  }
}

//input styling
.form-group {
  margin: 10px 0;

  .error-text {
    text-align: right;
    color: $danger-color;
  }
}

//backgrounds
.bg-primary {
  background-color: var(--primary-color);

  .side-nav-item,
  .user-item {
    color: var(--light-color) !important;
  }
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-light {
  background-color: var(--light-color);
}

.bg-success {
  background-color: $success-color;
}

.bg-danger {
  background-color: $danger-color;
}

.bg-info {
  background-color: $info-color;
}

.bg-warning {
  background-color: $warning-color;
}

//texts.foo
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-light {
  color: var(--light-color) !important;
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $danger-color;
}

.text-info {
  color: $info-color;
}

.text-warning {
  color: $warning-color;
}

.title {
  font-size: $title-size;
  font-weight: bold;
}

.title-xs {
  font-size: 1.2rem;
}

.title-sm {
  font-size: 1.6rem;
}

.title-md {
  font-weight: bold;
  font-size: 2.4rem;
}

.title-lg {
  font-weight: bold;
  font-size: 3.6rem;
}

.title-xl {
  font-weight: bold;
  font-size: 4.8rem;
}

.title-xxl {
  font-weight: bold;
  font-size: 5.4rem;
}

.mini-text {
  font-size: 0.8rem !important;
}

@media (max-width: 768px) {
  .title-sm {
    font-size: 1rem;
  }

  .title-md {
    font-size: 1.8rem;
  }

  .title-xxl {
    font-size: 3.2rem;
  }
}

.sub-title {
  font-size: $accent-size;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.bolder {
  font-weight: bolder;
}

.normal {
  font-weight: unset;
}

.uppercase {
  text-transform: uppercase;
}

.titlecase {
  text-transform: capitalize;
}

//cards

.card,
%card {
  border-radius: $sm;
  padding: $md;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
}

//
.elevated {
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);
}

//cursor

.pointer {
  cursor: pointer;
}

//scrollbar

/* width */
// ::-webkit-scrollbar {
//   width: 4px;
//   height: 12px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #d6d6d6;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

//cursors
.pointer {
  cursor: pointer;
}

.more-options {
  position: relative;
  background: transparent;
  border: none;
  color: var(--text-color);

  &:focus,
  &:active {
    outline: none;

    .drop-items {
      min-width: 230px;
      display: block;

      .drop-item {
        .pro-icon {
          height: 35px;
          background: rgba(0, 0, 0, 0.3);
          width: 35px;
          object-fit: cover;
          border-radius: 50%;
          object-position: center;
          margin-right: $sm;
        }

        img {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
        }

        label {
          flex: 1;
          text-align: left;
        }

        &.selected {
          i {
            display: block;
          }
        }

        i {
          color: var(--text-color);
          display: none;
        }
      }
    }
  }

  .drop-items {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--light-color);
    color: var(--text-color);
    height: 300px;
    overflow: auto;

    .drop-item {
      padding: $xs $md;
    }
  }
}

//ramble
.morning-rambles {
  overflow-y: auto;
  padding-top: 2vh;

  .rambles-list {
    .ramble-card {
      text-decoration: none;
      width: 23.5%;
      margin-right: 2%;

      &:nth-child(4n) {
        margin-right: 0;
      }

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
        height: 200px;
      }
    }
  }
}

.landing-rambles {
  margin-top: 12vh;
}

.test {
  position: relative;
}

.landing-rambles-image {
  background: url("../images/priahead.png") no-repeat fixed center;
  background-size: 20%;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 0;
}

.footer {
  .footer-col {
    div,
    p {
      color: var(--light-color);
    }
  }
}

.back-to-top-fab {
  height: 28px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #8989a1;
  position: sticky;
  color: white;
  cursor: pointer;

  .material-icons {
    color: white !important;
  }

  //border:1px solid var(--primary-color);
  bottom: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.back-to-top-fab:hover {
  background: #66dbc4;
}
