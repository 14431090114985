.pagination {
  position: absolute;
  bottom: 0px;
  right: 40px;
  //   background-color: #b0b0b0;
}

.pagination ul {
  display: flex;
  color: red;
}

.pagination ul li {
  list-style: none;
  padding: 20px;
  //   margin: 20px 0px;
}
.pagination ul li a {
  color: var(--text-color);
}
li.selected {
  /* color: red; */
  background-color: var(--primary-color);
  border-radius: 50%;
  /* height: 49px; */
  //   margin: 0px 0px;
  /* padding: 65px; */
  cursor: pointer;
}
