@use "../../../../style/scss/vars.scss" as vars;

.bottom-action {
  // position: absolute; // to be uncommented later as per requirements
  margin-top: auto !important;
  // position: absolute; to be uncommented later as per requirements
  margin-inline: auto;
  font-size: vars.$fs-md;
  padding-block: 12px !important;
  bottom: 48px;
  border-top: 1px solid rgba(vars.$secondary-0, 0.2);
  width: 100%;
  max-width: 592px;
  // padding-top: 12px !important;
  // border-top: 1px solid #1d1d4e33;
  color: vars.$secondary-0;

  .link {
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: inherit;
  }
}
