@use "../../../../style/scss/vars.scss" as vars;

.stepper-container {
  position: relative;
  padding-top: 1rem;
  width: 90%;
  max-width: 570px;
  font-size: 14px;
  margin-inline: auto;
  color: vars.$secondary-0;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 4px solid transparent;
      border-radius: 50%;

      .circle {
        background: #f5f5f9;
        color: #757575;
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }

      &.active {
        background-color: vars.$primary-0;
      }

      &.active .circle {
        background: white;
      }
    }

    .line {
      background-color: #f5f5f9;
      height: 5px;
      flex: 1;

      &.active-line {
        background-color: vars.$primary-0;
      }
    }
  }

  .back-button {
    position: absolute;
    left: -5.5rem;
    top: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: vars.$primary-0;
    }

    @media (min-width: 1000px) {
      left: -18%;
    }
  }

  @media (min-width: 1000px) {
    width: 80%;
  }
}
