@use "../../../style/scss/vars.scss" as vars;

.toggle-wrapper {
  display: inline-block;

  .ant-switch {
    min-width: unset !important;
    border-radius: 81px !important;
    background-color: #4f48e21f !important;
    border: 1px solid #4f48e21f !important;
  }

  .ant-switch-handle:before {
    background-color: vars.$primary-0 !important;
  }

  .ant-switch-handle {
    height: 14px;
    width: 14px;
    top: 1px;
  }

  .ant-switch:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-switch-checked {
    background-color: vars.$primary-0 !important;

    .ant-switch-handle {
      left: calc(100% - 14px - 2px) !important;

      &:before {
        background-color: #fff !important;
      }
    }
  }
}
