:root {
  --primary-color: #12507a;
  // --primary-color: #4f48e7;

  --dark-primary: #042d47;
  --secondary-color: #3e3e3e;
  --light-color: #ffffff;
  --text-color: black;
}

$success-color: #4caf50;
$danger-color: #f44336;
$info-color: #2196f3;
$warning-color: #ff9800;

//font-sizes
$title-size: 2.4rem;
$accent-size: 1.6rem;

//font-family
$title-font: "Noto Sans", "sans-serif";
$accent-font: "Noto Sans", "sans-serif";

//sizes
$xs: 4px;
$sm: 8px;
$md: 10px;
$lg: 24px;
$xl: 40px;

//layouting
$wrapper-val: 8vw;

// new vars
// colors
$primary-0: #4f48e2;
$primary-3: #b9b6f3;

$secondary-0: #1d1d4e;

$input-border: #f1f3f7;
$shadow-1: #1d1d4e1a;
$placeholder: #a0a0a0;

$text-gray: #4a4a4a;
$text-light-gray: #1d1d4e99;

$error-0: #ff644e;

$success: #13b253;

// font weights
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-bolder: 800;

// font-size
$fs-xs: 12px;
$fs-sm: 14px;
$fs-md: 16px;
$fs-lg: 18px;
$fs-xl: 20px;
$fs-2xl: 36px;

// border-radius
$radius-sm: 4px;
$radius-md: 6px;
$radius-lg: 8px;

// font family
$ff-poppins: Poppins;

main.dark-mode {
  .authenticated-page {
    --primary-color: #9ca1a8 !important;
    --dark-primary: #042d47 !important;
    --secondary-color: #3e3e3e !important;
    --light-color: #1d1526 !important;
    --text-color: #ffffff !important;

    .bg-primary {
      --primary-color: #221e2c !important;
    }

    .btn {
      --light-color: #fffff !important;
    }

    .side-nav-item,
    .user-item {
      --light-color: #fffff !important;
    }

    //.rumble-column .header img {
    //  filter: invert(0) !important;
    //}
  }
}
