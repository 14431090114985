.cmegroup-app-container {
  margin: 2px 0 0 4px !important;
  position: relative;
  overflow: hidden;
  border: 1px solid #d5d5d5;
  border-radius: 4px;

  .card {
    background: #ebedee;
    padding: 20px;
    max-width: 483px;
    margin: 11px 0px 15px;
    box-shadow: none !important;
    border-radius: 0px !important;

    .card-header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #ced0d4;

      h3 {
        font-size: 16px;
        margin-bottom: 0px !important;
        font-weight: bold !important;
        color: #122b4a;
      }

      svg {
        color: #25a9e0;
      }
    }

    .card-para {
      line-height: 22px;
      font-size: 14px !important;
      margin-bottom: 0 !important;
      color: #5a6874;

      a {
        color: #25a9e0;
        text-decoration: underline;
      }
    }
  }

  .detail-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: white;
    padding: 7px;
    display: flex;
    flex-direction: column;

    .navigation {
      padding: 4px 20px 16px;
      display: flex;
      align-items: flex-start;
      gap: 4px;
      font-weight: 500;

      .link {
        color: #25a9e0;
        white-space: nowrap;
      }
    }

    .iframe-container {
      height: 100%;

      .loader {
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
      }
      // margin-bottom:2em;
    }
  }

  .v2-column-header-option {
    margin-left: 0px !important;
    height: 100% !important;
    margin-top: 0px !important;
    border: none !important;
  }

  .main-area {
    height: 100%;
    background: white;
    overflow: auto;

    .logo {
      margin-bottom: 16px;
      width: 155px;
      height: 26px;
      margin-left: -9px;
    }

    .cmegroup-content {
      padding: 22px 19px;

      p {
        font-size: 13px;
      }

      .container {
        padding: 0 20px;

        h3 {
          margin-bottom: 6px;
        }

        .sub-heading {
          font-size: 16px;
          font-weight: bold;
        }

        .sub-heading-para {
          display: block;
          margin-bottom: 6px;
          font-size: 12px;
          font-weight: 500;
          max-width: 483px;
        }
        .sub-heading-para-2 {
          margin-bottom: 15px;
          font-size: 13px;
          max-width: 491px;
        }

        .ant-collapse {
          border: none !important;
          background: transparent !important;

          .ant-collapse-item {
            border-bottom: none !important;
            margin-bottom: 4px;
          }
        }

        .ant-collapse-content {
          border-top: none !important;

          .ant-collapse-content-box {
            padding: 0px 0px 7px 0px !important;

            p {
              margin-bottom: 8px;
              max-width: 492px;
            }
          }
        }

        .ant-collapse-header {
          padding: 0px !important;
          font-size: 14px !important;
          // padding-bottom:7px !important;

          .ant-collapse-header-text {
            font-weight: bold !important;
            height: 40px;
            display: flex;
            align-items: center;
          }
        }

        .list-container {
          background: #f6f7f9;
          .list-item {
            display: grid;
            grid-template-columns: 22px 1fr;
            height: 40px;
            gap: 10px;
            padding: 9px 10px;
            cursor: pointer;

            svg {
              font-size: 22px;
            }

            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .list-item-title {
              font-size: 14px;
            }

            &:hover {
              background: #d7eff9;
            }
          }
        }
      }
    }
  }
}

.cmegroup-app-container.dark {
  color: white;
  border: 1px solid #9ca1a8;

  .card {
    background: #303742;

    h3,
    p {
      color: #ced0d4;
    }
  }

  .detail-container {
    background: #384251;
  }

  h3 {
    color: white;
    font-weight: 700;
  }

  .main-area {
    background: #384251;

    .ant-collapse,
    .ant-collapse-content,
    .ant-collapse-header,
    .ant-collapse-item {
      background: transparent !important;
      color: white !important;
      border: none !important;
    }

    .container {
      .list-container {
        background: rgba(48, 55, 66, 0.6);
        color: #ced0d4;

        .list-item:hover {
          background: #515a67;
        }
      }
    }
  }
}
